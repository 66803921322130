// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}
.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;AACA,mBAAmB,qBAAqB;AACxC,gBAAgB,qBAAqB;AACrC,+BAA+B,qBAAqB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap\");\nbody {\n  font-family: \"DM Sans\", sans-serif;\n}\n\noption {\n  color: black;\n}\n.leaflet-control { z-index: 0 !important}\n.leaflet-pane { z-index: 0 !important}\n.leaflet-top, .leaflet-bottom {z-index: 0 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
